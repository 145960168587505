import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_vm.documentExists ? _c('div', [_c(VContainer, [_c(VDialog, {
    attrs: {
      "scrollable": "",
      "max-width": "900px"
    },
    model: {
      value: _vm.upgradeDialog,
      callback: function ($$v) {
        _vm.upgradeDialog = $$v;
      },
      expression: "upgradeDialog"
    }
  }, [_c(VCard, {
    staticClass: "px-5 py-3"
  }, [_c(VCardTitle, {
    staticClass: "headline justify-center"
  }, [_vm._v("Upgrade von " + _vm._s(_vm.fromUpgade) + " zu " + _vm._s(_vm.toUpgrade.bezeichnung) + " duchführen?")]), _c(VCardText), _c(VCardActions, [_c(VSpacer), _c(VBtn, {
    attrs: {
      "color": "primary lighten-1 px-8"
    },
    on: {
      "click": function ($event) {
        return _vm.upgradeDocument(_vm.toUpgrade);
      }
    }
  }, [_vm._v("UPGRADE")]), _c(VBtn, {
    attrs: {
      "color": "error",
      "text": ""
    },
    on: {
      "click": function ($event) {
        _vm.upgradeDialog = false;
      }
    }
  }, [_vm._v("Abbrechen")])], 1)], 1)], 1), _c('SiteHeaderComponent', {
    attrs: {
      "header": _vm.siteTitle
    }
  }), _c('h4', {
    staticClass: "text-center mb-10"
  }, [_vm._v("Status: " + _vm._s(_vm.document.state))]), _c('div', {
    staticClass: "text-center mb-8"
  }, [_c(VBtn, {
    staticClass: "mr-3",
    attrs: {
      "outlined": "",
      "loading": _vm.document.printLoading
    },
    on: {
      "click": _vm.printDocument
    }
  }, [_c(VIcon, [_vm._v("mdi-printer")]), _vm._v(" Drucken ")], 1), _c(VMenu, {
    attrs: {
      "offset-y": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function ({
        on,
        attrs
      }) {
        return [_c(VBtn, _vm._g(_vm._b({
          attrs: {
            "color": "primary",
            "disabled": _vm.possibleUpgrades == null || _vm.possibleUpgrades.length == 0
          }
        }, 'v-btn', attrs, false), on), [_vm._v(" Upgrade "), _c(VIcon, {
          staticClass: "ml-2"
        }, [_vm._v("mdi-chevron-down")])], 1)];
      }
    }], null, false, 1763981368)
  }, [_c(VList, [_vm.possibleUpgrades.length == 0 ? _c(VListItem, [_c(VListItemTitle, [_vm._v("Keine Upgrades für diesen Dokumenttyp verfügbar!")])], 1) : _vm._e(), _vm._l(_vm.possibleUpgrades, function (item, index) {
    return _c(VListItem, {
      key: index,
      on: {
        "click": function ($event) {
          return _vm.upgradeDocumentDialog(item);
        }
      }
    }, [_c(VListItemTitle, [_vm._v(_vm._s(item.name))])], 1);
  })], 2)], 1)], 1), _c(VCard, {
    staticClass: "col-lg-6 col-12 rounded-lg mb-3 mx-auto elevation-3"
  }, [_c('h4', {
    staticClass: "mr-8 font-weight-bold text-upercase text-break"
  }, [_c(VCardTitle, [_vm.address.firstname == undefined && _vm.address.lastname == undefined ? _c('span', [_vm._v(_vm._s(_vm.address.name))]) : _c('span', [_vm._v(_vm._s(_vm.address.firstname + ' ' + _vm.address.lastname))])])], 1), _c(VCardText, {
    staticClass: "mb-3"
  }, [_c(VRow, {
    staticClass: "mx-0"
  }, [_c(VCol, {
    staticClass: "pa-0"
  }, [_vm._v(" Straße: ")]), _c(VCol, {
    staticClass: "pa-0"
  }, [_vm._v(" " + _vm._s(_vm.address.address.street) + " ")])], 1), _c(VRow, {
    staticClass: "mx-0"
  }, [_c(VCol, {
    staticClass: "pa-0"
  }, [_vm._v(" Ort: ")]), _c(VCol, {
    staticClass: "pa-0"
  }, [_vm._v(" " + _vm._s(_vm.address.address.zip) + " " + _vm._s(_vm.address.address.city) + " ")])], 1), _c(VRow, {
    staticClass: "mx-0"
  }, [_c(VCol, {
    staticClass: "pa-0"
  }, [_vm._v(" Land: ")]), _c(VCol, {
    staticClass: "pa-0"
  }, [_vm.address.address.country.lfdnr == 1 ? _c('span', [_vm._v(_vm._s('Austria') + " ")]) : _vm.address.address.country.lfdnr == 15 ? _c('span', [_vm._v(_vm._s('Germany') + " ")]) : _vm._e()])], 1)], 1)], 1), _c(VCard, {
    staticClass: "mx-auto my-12",
    attrs: {
      "loading": _vm.loading,
      "elevation": "0"
    }
  }, [_c('template', {
    slot: "progress"
  }, [_c(VProgressLinear, {
    attrs: {
      "color": "primary",
      "height": "10",
      "indeterminate": ""
    }
  })], 1), _c(VCardTitle, {
    staticClass: "border-bottom-primary-3"
  }, [_vm._v(" Positionen ")]), _c(VContainer, {
    staticClass: "pb-4"
  }, [_c(VTextField, {
    attrs: {
      "append-icon": "mdi-magnify",
      "label": "Suche",
      "outlined": "",
      "hide-details": ""
    },
    model: {
      value: _vm.search,
      callback: function ($$v) {
        _vm.search = $$v;
      },
      expression: "search"
    }
  })], 1), _c(VDataTable, {
    staticClass: "elevation-0",
    attrs: {
      "headers": _vm.headers,
      "items": _vm.document.positions,
      "search": _vm.search
    },
    scopedSlots: _vm._u([{
      key: "item.deliveryPrice",
      fn: function ({
        item
      }) {
        return [_c('span', [_vm._v(_vm._s(_vm.formatCurrency(item.deliveryPrice)))])];
      }
    }, {
      key: "item.totalPrice",
      fn: function ({
        item
      }) {
        return [_c('span', [_vm._v(_vm._s(_vm.formatCurrency(item.totalPrice)))])];
      }
    }], null, false, 2906224649)
  })], 2), _c(VCard, {
    staticClass: "mb-12",
    attrs: {
      "elevation": "1"
    }
  }, [_c(VCardTitle, {
    staticClass: "border-bottom-primary-3"
  }, [_vm._v("Interaktionen")]), _c('InteractionsTable', {
    staticClass: "elevation-0",
    attrs: {
      "lfdnr": _vm.lfdnr,
      "wf_cid": _vm.wf_cid
    }
  })], 1), _c(VSnackbar, {
    scopedSlots: _vm._u([{
      key: "action",
      fn: function ({
        attrs
      }) {
        return [_c(VBtn, _vm._b({
          attrs: {
            "color": "green darken-1",
            "text": ""
          },
          on: {
            "click": function ($event) {
              return _vm.openUpgradedDocument();
            }
          }
        }, 'v-btn', attrs, false), [_vm._v(" Dokument ansehen ")])];
      }
    }], null, false, 4218944648),
    model: {
      value: _vm.SuccessSnackbar,
      callback: function ($$v) {
        _vm.SuccessSnackbar = $$v;
      },
      expression: "SuccessSnackbar"
    }
  }, [_vm._v(" Upgrade erfolgreich durchgeführt! ")]), [_c('ErrorDialog', {
    attrs: {
      "failMessage": _vm.failMessage,
      "opened": _vm.errorDialog
    },
    on: {
      "closeDialog": function ($event) {
        _vm.errorDialog = false;
      }
    }
  })]], 2)], 1) : _vm._e(), !_vm.documentExists ? _c(VContainer, [_c(VCard, {
    staticClass: "pa-5 mt-16",
    staticStyle: {
      "border-radius": "15px"
    },
    attrs: {
      "elevation": "0"
    }
  }, [_c(VLayout, {
    attrs: {
      "column": "",
      "align-center": "",
      "justify-center": "",
      "fill-height": ""
    }
  }, [_c(VFlex, {
    staticClass: "text-center mb-4",
    attrs: {
      "xs12": ""
    }
  }, [_c('h3', [_vm._v(" Das gesuchte Dokument konnte nicht gefunden werden ")])]), _c(VFlex, {
    attrs: {
      "xs12": ""
    }
  }, [_c(VImg, {
    staticClass: "mx-auto my-auto",
    attrs: {
      "src": require("@/assets/svg/undraw_warning_re_eoyh.svg"),
      "max-width": "350px",
      "contain": ""
    }
  })], 1)], 1)], 1)], 1) : _vm._e()], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };