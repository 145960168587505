import axios from 'axios';
import SiteHeaderComponent from '@/components/core/SiteHeaderComponent.vue';
import InteractionsTable from '@/components/crm/addresses/InteractionsTable.vue';
import currencyFormatService from '@/services/formatters/currencyFormatService.js';
import documentService from '@/services/crm/documentService.js';
import ErrorDialog from '@/components/core/ErrorDialog.vue';
import addressService from '@/services/crm/addressService.js';

//https://diegoazh.github.io/gmap-vue/

export default {
  components: {
    SiteHeaderComponent,
    InteractionsTable,
    ErrorDialog
  },
  data: () => ({
    address: {
      key: {
        lfdnr: '',
        firma: ''
      },
      address: {
        street: '',
        zip: '',
        country: {
          firma: '',
          sprache: '',
          lfdnr: ''
        }
      }
    },
    document: {
      name: '',
      nr: '',
      date: '',
      type: '',
      shortType: '',
      state: '',
      wf_cid: '',
      printLoading: false
    },
    search: '',
    loading: true,
    upgradeDialog: false,
    possibleUpgrades: [],
    fromUpgade: '',
    toUpgrade: '',
    SuccessSnackbar: false,
    upgradedDocument: {},
    documentExists: true,
    headers: [{
      text: 'Artikel',
      value: 'article'
    }, {
      text: 'Lieferpreis',
      value: 'deliveryPrice',
      align: 'end'
    }, {
      text: 'Gesamtpreis',
      value: 'totalPrice',
      align: 'end'
    }, {
      text: 'SM',
      value: 'sm',
      align: 'end'
    }, {
      text: 'Text',
      value: 'positionText',
      align: 'end'
    }, {
      text: 'Menge',
      value: 'quantity',
      align: 'end'
    }, {
      text: 'LMenge',
      value: 'lQuantity',
      align: 'end'
    }, {
      text: 'VMenge',
      value: 'vQuantity',
      align: 'end'
    }, {
      text: 'EH',
      value: 'unit',
      align: 'end'
    }, {
      text: 'Status',
      value: 'state',
      align: 'end'
    }],
    failMessage: '',
    errorDialog: false
  }),
  props: {
    lfdnr: {
      type: Number,
      required: true
    },
    wf_cid: {
      type: String,
      required: true
    }
  },
  methods: {
    init() {
      this.documentExists = true;
      this.$store.dispatch('ensureAvailableAppsLoaded');
      this.loadDocument();
      this.loadAddress();
      this.getPossibleUpgrades();
      if (this.$refs.myMap != null) this.$nextTick(() => {
        this.$refs.myMap.mapObject.ANY_LEAFLET_MAP_METHOD();
      });
    },
    openUpgradedDocument() {
      this.$router.replace({
        path: `/crm/addresses/${this.upgradedDocument.kdnr}/documents/${this.upgradedDocument.wfCid}`
      }).then(() => window.location.reload());
    },
    async loadDocument() {
      await axios.get(`/v1/crm/documents/${this.wf_cid}`).then(response => {
        response.data.printLoading = false;
        this.document = response.data;
        this.document.name = `${this.document.shortType} ${this.document.nr}`;
        if (response.status == '404') {
          this.documentExists = false;
        }
        this.loading = false;
      }).catch(error => {
        console.log(error);
        this.documentExists = false;
      });
    },
    loadAddress() {
      addressService.getAddress(this.lfdnr).then(response => {
        console.warn(response);
        if (response.status !== 200) {
          this.addressExists = 'false';
        } else {
          this.addressExists = 'true';
        }
        this.address = response.data;
        this.loading = false;
        this.addressTypeName = this.address.addressTypes;
      }).catch(error => {
        console.error(error);
        this.loading = false;
      });
    },
    addressTypesIcons(item) {
      return addressService.getAddressTypeDetailed(item).icon;
    },
    addressTypesNames(item) {
      //this.adressTypeName.push(addressService.getAddressTypeDetailed(item).name)
      return addressService.getAddressTypeDetailed(item).name;
    },
    printDocument() {
      documentService.printDocument(this.document);
    },
    formatCurrency(value) {
      return currencyFormatService.formatEuro(value);
    },
    upgradeOffer() {
      this.upgradeDialog = true;
    },
    async getPossibleUpgrades() {
      if (this.$store.getters.userHasApp('LBA-Business')) {
        await axios.get(`/v1/offer/${this.wf_cid}/availableUpgrades`).then(response => {
          console.log(response.data);
          if (response.status == 200) {
            this.possibleUpgrades = response.data;
          } else {
            this.failMessage = 'Beim Laden der möglichen Upgrades ist ein Fehler aufgetreten!';
            this.error = true;
          }
        });
      }
    },
    upgradeDocumentDialog(upgradeType) {
      this.fromUpgade = this.document.type;
      this.toUpgrade = upgradeType;
      this.upgradeDialog = true;
    },
    upgradeDocument(upgrade) {
      axios.patch(`/v1/business/document/${this.document.wf_cid}/upgrade`, upgrade).then(res => {
        if (res.status == 200) {
          this.upgradedDocument = res.data;
          this.SuccessSnackbar = true;
          this.getPossibleUpgrades();
        }
      });
      this.upgradeDialog = false;
    }
  },
  computed: {
    siteTitle() {
      return this.document.name;
    }
  },
  mounted() {
    this.init();
  },
  watch: {
    '$route.params': 'init'
  }
};